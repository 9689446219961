/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.TryNow {
    // margin-block-start: 24px;
    @include mobile {
        margin-block-start: 0;
        margin-inline-start: 2px;
    }
    &-Wrapper{
        order:99;
        .Orbo-Button, .Orbo-Button:not([disabled]):hover{
            cursor: pointer;
            padding: 6px 20px;
            background: #de4d47;
            color: var(--color-white);
            border-color: var(--button-border);
            border-style: solid;
            border-width: var(--button-border-width);
            display: flex;
            align-items: center;
            gap: 4px;
            svg, * {
                color: var(--color-white);
            }
        }
        .ProductWishlistButton-Button.Button:hover{
            padding: 0;
        }
    }
    .Orbo-Button {
        @include mobile {
            padding-inline: 0 !important;
            width: 100%;
        }
    }
    .CloseIcon{
        position: absolute;
        top: 4px;
        right: 4px;
    }

}

#orboiframewrapper{
    z-index: 999999999;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    @include mobile {
        bottom: 60px;
    }
}