/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.VideoCard {
    &-Modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        &Overlay {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.4);
            width: 100%;
            height: 100%;
        }
        &Close {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 30px;
            @include mobile {
                height: 44px;
                z-index: 1;
                width: 44px;
                background: rgba(0,0,0, 0.3);
                border: 3px solid var(--color-black);
                border-radius: 6px;
            }
        }
        &Content {
            background-color: var(--color-white);
        }
    }
    &-Product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.3s linear;
        @include mobile {
            opacity: 0;
            height: 70vh;
            position: absolute;
            background: var(--color-white);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            bottom: -74vh;
            left: 0;
        }
        &_isActive {
            width: 100%;
            opacity: 1;
            bottom: 0;
            .VideoCard {
                &-ProductName {
                    padding-inline: 15px;
                    margin-top: 10px;
                }
                &-Price {
                    padding-inline: 15px;
                }
            }
        }
        &-Container {
            display: grid !important;
            grid-template-columns: 50% 50%;
            gap: 3px;
            overflow: hidden !important;
            @include mobile {
                grid-template-columns: repeat(1, 1fr);
            }
            > .AddToCart {
                width: fit-content;
                min-width: fit-content;
                height: fit-content;
                padding: 10px;
                border-radius: 50%;
                position: absolute;
                border: 1px solid rgba(255,255,255,.2) !important;
                bottom: 90px;
                right: 10px;
                svg {
                    width: auto;
                    height: 18px;
                    fill: none;
                    margin-inline-end: 0;
                    path {
                        stroke: white;
                        stroke-width: 2;
                    }
                }
                span {
                    display: none;
                }
                @include desktop {
                    display: none;
                }
            }
            .ProductCard {
                height: unset;
                min-height: 470px;
                @include mobile {
                    min-height: auto;
                    margin-top: auto;
                }

                &-Name {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 600;
                }

                &-Footer {
                    align-items: flex-start;
                    flex-direction: column;
                    padding: 0 5px 5px 5px;
                    position: absolute;
                }

                &-PreviewOptions {
                    overflow: scroll;
                    border: 2px solid #e5e5e5;
                }

                &-AddToCart {
                    height: var(--button-height);
                    span {
                        font-size: 16px;
                    }
                }
                
                &-Qty {
                    margin: 0;

                    input {
                        color: var(--color-black);
                        border: 0;
                        font-size: 18px;
                        font-weight: 700;
                        height: auto;
                        padding: 0;
                        order: 2;
                        text-align: center;
                        width: 30px;
                        pointer-events: none;
                        min-width: auto;
                        min-height: auto;
                    }

                    button {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        &-Img {
            @include mobile {
                height: 100vh !important;
                width: 100vw !important;
                max-height: unset !important;
            }
        }
    }
    &-ProductDetails {
        max-height: calc(633px - 69px);
        overflow: scroll;
        @include mobile {
            margin-top: 20px;
        }
    }
    &-ProductHeader {
        position: absolute;
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &Center {
            height: 5px;
            width: 40px;
            border-radius: 5px;
            background: rgba(189, 189, 189, 0.8);
            margin-left: 30px;
        }
        .VideoCard-ModalClose {
            position: unset;
            height: 20px;
            width: 26px;
            border: 0;
            background-color: transparent;
        }
    }
    &-Images {
        display: flex;
        grid-gap: 15px;
        gap: 15px;
        overflow-x: scroll;
        padding: 15px;
        padding-bottom: 0;
        > div {
            flex: 0 0 80%;
        }
    }
    &-ProductName {
        font-weight: 600;
        font-size: 16px;
        margin-block: 10px 3px;
        font-family: 'Montserrat';
        padding-inline: 15px;
        @include mobile {
            text-align: left;
            margin-block-start:0;
            padding-inline: 0;
        }
    }
    &-Price {
        margin-top: 0 !important;
        padding-inline: 15px;
        @include mobile {
            padding-inline: 0;
        }
        .ProductPrice{
            &-PriceValue {
                font-size: 14px;
            }
            &-HighPrice {
                font-size: 13px;
            }
            &-DiscountPercentage {
                font-size: 12px;
            }
        }
    }
    &-ProductContent {
        border-top: 5px solid #e8e6e6;
        border-bottom: 5px solid #e8e6e6;
        padding-inline: 15px;
        padding-block: 10px;
        margin-block: 10px;
        * {
            font-size: 12px;
            line-height: 16px;
        }
        li {
            margin-left: 0 !important;
        }
    }
    &-ProductContentText {
        &_isExpandable {
            max-height: 50px;
            overflow: hidden;
        }
        &_isExpanded {
            max-height: unset !important;
        }
    }
    &-ProductContentButton {
        margin-block-start: 10px;
        font-weight: 600;
    }
    &-Actions {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 10px;
        background: var(--color-white);
        padding: 10px 15px;
        @include mobile {
            position: unset;
        }
        &Link {
            border: 2px solid var(--color-black);
            min-width: max-content;
            padding: 12px;
        }
        &Button {
            border: 2px solid var(--color-black);
            padding: 9px;
            @include mobile {
                align-self: flex-end;
                background: rgba(0,0,0, 0.3);
                border-radius: 6px;
            }
        }
    }
    &-MobileFooter {
        position: absolute;
        bottom: 0;
        width: 100%;
        &Actions {
            margin: 10px;
            display: flex;
            flex-direction: column;
            width: calc(100% - 20px);
            gap: 18px;
        }
        &Button{
            @include mobile {
                display: flex;
                align-items: center;
                background: rgba(255, 255, 255, 0.8);
                padding: 5px;
                border-radius: 5px;
                gap: 5px;
            }
            .Image {
                width: 85px;
                height: 85px;
                padding-bottom: 0;
            }
            .Image-Image {
                width: 85px;
                height: 85px;
                object-fit: cover;
            }
        }
        &Content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &Shopnow {
            color: var(--color-white);
            background-color: #71333F;
            border-radius: 5px;
            padding: 5px 7px;
        }
    }
}