/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.scroll-to-top {
    position: fixed;
    inset-block-end: 20px;
    inset-inline-end: 20px;
    background-color: #CEBC89;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 99999999;
    display: none;

    svg {
        height: 20px;
        width: 20px;
        fill: #000;
    }
  
    &:hover {
        background-color: #ad9c6c;
        
        svg {
            fill: #fff;
        }
    }
}

.visible {
    display: block;
}
