/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.MeterGauge {
    max-width: 251px;
    margin-inline: auto;
    &-Needle {
        transition: all 2s linear;
        left: 11.5%;
        position: absolute;
        right: 0;
        bottom: 25%;
        transform-origin: right center;
        transform: rotate(0deg);
        width: 100px !important;
    }
}
