/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.PlpFooter {
    background-color: #161616;
    h1, h2, h3, h4, h5, h6, th {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: var(--color-white) !important;
    }
    p, li, li *, td, a {
        color: #8B8B8B !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    &ContentWrapper {
        max-height: 200px;
        overflow: hidden;
        &.active {
            // height: auto;
            max-height: 100%;
        }
    }
    .ReadMore {
        text-align: center;
        padding: 10px;
        font-weight: 500;
        border-top: 1px solid rgba(101, 119, 134, 0.22);
        color: #f7f6f6;
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 15px;
    }
    .ReadMore:hover {
        cursor: pointer;
    }
}
