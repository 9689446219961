/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.FlashSale {
    display: flex;
    //position: fixed;
    //top: var(--header-total-height);
    //z-index: 2;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;

    &-Wrapper {
        display: flex;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        background-color: #edd087;
        max-width: var(--content-wrapper-width);
        margin-inline: auto;
    
        @include mobile {
            width: calc(100% - 28px);
        }
    }

    &-Left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: -webkit-fill-available;
        padding: 1.5% 4% 1.5% 8%;
        row-gap: 5px;
    }

    &-Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-Heading {
        font-size: 24px;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        color: black;
        letter-spacing: 1px;

        svg {
            width: 16px;
            height: auto;
            margin-left: 2px;
        }
        @include mobile {
            font-size: 14px;
            svg {
                width: 12px;
                margin-left: 4px;
            }
        }
    }

    &-Items {
        font-size: 16px;
        font-weight: 600;
        line-height: 10px;
        text-transform: capitalize;
        color: #4f4f4f;
        @include mobile {
            font-size: 11px;
            font-weight: 400;
        }
    }

    &-ProgressBar {
        width: 100%;
        height: 8px;
        background: linear-gradient(to left, #2ea35b, #cf1e25);
        border-radius: 46px;
        @include mobile {
            height: 4px;
        }

        &_Active {
            background-color: #edd087;
            width: 70%;
            height: 8px;
            float: right;
            border-radius: 0 46px 46px 0;
            @include mobile {
                height: 4px;
            }
        }
    }

    &-Right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        background-color: #ab9043;
        white-space: pre;
        border-radius: 8px;
        justify-content: center;
        width: 197px;
        justify-content: space-evenly;
        @include mobile {
            width: 140px;
        }
    }

    &-DateHeading {
        font-size: 18px;
        font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
        color: white;
        letter-spacing: 0.2px;
        @include mobile {
            font-size: 12px;
        }
    }

    &-DateText {
        font-size: 20px;
        font-weight: 600;
        line-height: 14px;
        color: white;
        letter-spacing: 1px;
        letter-spacing: 0.2px;
        @include mobile {
            font-size: 12px;
        }
    }
}
