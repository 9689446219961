/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 .HomePage{
    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }
    &-BeautyOfHalal {
        background-color: #f6f5f3;
        &MainBanner  {
            @include mobile {
                width: 100%;
            }
        }
        &Wrapper {
            max-width: var(--content-wrapper-width);
            margin-inline: auto;
            padding-block: 40px;
            display: flex;
            align-items: center;
            @include mobile {
                padding: 30px 20px;
            } 
            > * {
                flex: 1;
            }
        }
        &Heading {
            font-family: 'Playfair Display';
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            color: #71333F;
            text-align: center;
            @include mobile {
                font-size: 24px;
                line-height: 32px;
            }
        }
        &Text, &ClientsHeading {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #464646;
            margin-top: 20px;
            @include mobile {
                font-size: 12px;
                line-height: 20px;
            }
        }
        &Link {
            display: block;
            width: max-content;
            background-color: #ecc09d;
            text-align: center;
            border: 1px solid var(--color-black);
            margin-inline: auto;
            margin-block: 20px 30px;
            border-radius: 3px;
            padding: 8px 16px;
            text-transform: uppercase;
        }
        &SubHeading {
            letter-spacing: 0.05em;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);
            text-align: center;
            text-transform: capitalize;
        }
        &Features {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            margin-block: 30px 35px;
            @include mobile {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &Feature {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &Image {
                width: 80px;
                height: 80px;
                object-fit: contain;
            }
            &Heading {
                font-weight: 600;
                font-size: 14px;
                text-align: center;
                text-transform: uppercase;
            }
        }
        &Clients {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            margin-top: 15px;
            @include mobile {
                width: 100%;
                max-width: calc(100vw - 40px);
                overflow: scroll;
                justify-content: flex-start;
            }
        }
        &Client {
            width: 65px;
            height: 65px;
            object-fit: contain;
        }
    }
}
.slick-slide img {
    margin-bottom: -4px;
}
.category-slider {
    background-color: #FAF6EB;
    min-height: 100px;
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    padding-block: 16px;
    @include mobile {
        padding-block: 10px;
        max-width: 100%;
    }
    ul {
        display: flex;
        overflow: scroll;
        column-gap: 8px;
        @include mobile {
            margin-top: 0 !important;
        }
        li {
            @include mobile {
                margin-bottom: 0;
            }
            &::before {
                @include mobile {
                    content: none;
                }
            }
        }
        a {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 94px;
            width: auto;
            align-items: center;
            picture {
                height: auto;
                width: auto;
                img {
                    height: 69px;
                    width: 69px;
                }
            }
            span{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #000000;
            }
        }
    }
}
.banner-slider{
    width: 100%;
    .slick-dots > li > button {
        width: 12px;
        height: 12px;
        padding: 5px;
        border: none;
        border-radius: 50%;
        background: #0d4c99;
        opacity: 0.4;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        &::before {
            display: none;
        }
    }
    .slick-active > button{
        background: #0d4c99 !important;
        opacity: 1!important;
    }
    .slick-dots {
        position: absolute;
        bottom: 16px;
        @include mobile {
            bottom: 0;
        }
    }
    .slick-slider{
        @include mobile {
            padding-bottom: 24px;
        }
    }
    @include mobile {
        min-height: 240px;
        .slick-list {
            min-height: 240px;
        }
        img {
            min-height: 240px;
        }
    }
    &-mobile {
        width: 100%;
        @include mobile {
            display: flex;
            align-items: center;
            min-height: 221px;
        }
    }
    &-mobile-short {
        padding-left: 5%;
        width: 100%;
        @include mobile {
            display: flex;
            align-items: center;
            min-height: 221px;
        }
    }
}
.InLineBanner {
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    padding-block-start: 40px;
    padding-block-end: 40px;
    @include mobile {
        padding: 0;
    }
    &-Img {
        margin-bottom: -4px;
        width: 100%;
    }
}
.TrendingSlider{
        margin-inline: auto;
max-width: var(--content-wrapper-width);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 464px;
    padding-block: 40px;
    @media (max-width: 1200px) {
        padding-inline: 5%;
    }
    @include mobile {
        height: 595px;
        padding-block: 24px;       
    }
    &-title {
        align-self: center;
        margin-block-end: 45px;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            @include mobile {
                font-size: 24px;
            }
        }

    }
    &-slides {
        @include desktop {
            //     margin-inline: auto;
            max-width: var(--content-wrapper-width);
        }
        .slick-slide{
            div {
                display: flex;
                justify-content: center;
            }
        }

        .slick-track {
            column-gap: 24px;
        }
    }
    &-Wrap{
        display: flex!important;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 207px!important;
        height: 280px;
        background-color: white;
        border: 1px solid #E7E7E7;
        box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        img {
            width: 100%;
            height: 207px;
        }
        @include mobile {
            width: 144px!important;
            height: 201px;
            margin-bottom: 24px;
            img {
                width: 100%;
                height: 144px;
                border-radius: 50%;
            }
        }
    }
    &-Content{
        display: flex;
        flex-direction: column;
        padding: 16px;
        &-Name{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #161616;
            @include mobile {
                font-family: 'Agrandir';
                font-weight: 800;
                font-size: 14px;
                line-height: 18px;
            }
        }
        &-Content{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #8B8B8B;
            @include mobile {
                padding: 4px;
                font-size: 10px;
                line-height: 13px;
            }
        }
    }
}
.productBanner{
    &-Wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-block: 20px;
        width: 100%;
        @include desktop {
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            min-height: 285px;
        }
        @include mobile {
            display: none;
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            min-height: 196px;
            margin-top: 10px;
            display: flex;
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                li {
                    margin-left: 10px;
                    img {
                        width: 242px;
                    }
                }
            }
        }
    }
    &-Img {
        width: 49%;
    }
}
.productImg{
    &-Wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-block: 20px;
        width: 100%;
        @include desktop {
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            min-height: 285px;
        }
        @include mobile {
            display: none;
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            min-height: 196px;
            margin-top: 10px;
            display: flex;
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                li {
                    margin-left: 10px;
                    img {
                        width: 242px;
                    }
                }
            }
        }
    }
    &-Img {
        width: 24%;
    }
}
.CheckNowBanner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    // min-height: 270px;
    // padding-block: 40px;
    row-gap: 20px;
    @media (max-width: 1200px) {
        padding-inline: 0%;
        padding-block: 0;
    }
    &-slides{
        display: flex;
        width: 100%;
        justify-content: space-between;
        // @include mobile {
            // justify-content: flex-start;
        // }
        @include mobile {
            > div > div, > div, .CustomSlider .HtmlSlider {
                width: 100%;
            }
        }
        ul {
            @include mobile {
                display: flex !important;
                justify-content: center;
            }
            flex-direction: row;
            overflow: scroll;
            column-gap: 16px;
        }
    }
    &-Container{
        width: 48%;
        @include mobile {
            width: 100%;
            height: auto;
        }
    }
    &-Img {
        width: 100%;
        height: 100%;
    }
    &-Content{
        display: none;
        position: absolute;
        inset-block-end: 15%;
        inset-inline-start: 5%;
        width: 267px;
        z-index: 12;
        @include mobile {
            width: auto;
            inset-inline: 10px;
            inset-block-end: 12px;
        }
    }
    &-Text{
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        @include mobile {
            font-size: 10px;
            line-height: 12px;
        }
    }
    &-Link{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-block-start: 24px;
        width: fit-content;
        padding: 8px 20px;
        background-color: #000000;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: #FFFFFF;
        @include mobile {
            margin-block-start: 8px;
            padding: 0;
            font-size: 10px;
            line-height: 12px;
            width: 70px;
            height: 20px;
        }
        &:hover {
            background-color: white;
            color: #000000;
            border: 1px solid black;
        }
    }
}
.HalalSuperfoods{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    min-height: 270px;
    padding-block: 40px;
    @media (max-width: 1200px) {
        padding-inline: 5%;
        padding-block: 24px;
    }
    &-title {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
        }
        @include mobile {
            margin-bottom: 12px;
            span {
                font-size: 24px;
                line-height: 26px;
            }
        }
    }
    &-slides{
        display: flex;
        width: 100%;
        gap: 20px;
        @include desktop {
            justify-content: space-between;
        }
        > a {
            display: block;
        }
        ul {
            @include mobile {
                display: flex !important;
            }
            flex-direction: row;
            overflow: scroll;
            column-gap: 16px;
        }
    }
    &-Img {
        width: 100%;
        @include mobile {
            //width: 156px;
            //height: 190px;
            width: 100%;
        }
    }
}
.HalalProducts {
    background: #FAF6EB;
    display: flex;
    flex-direction: column;
    @include desktop {
        //justify-content: space-between;
        min-height: 553px;
        padding-top: 60px;
        padding-bottom: 50px;
        align-items: center;
        row-gap: 40px;
    }
    @media (max-width: 1200px) {
        padding-inline: 5%;
    }
    @include mobile {
        height: 320px;
        padding-block: 24px;
    }
    &-Heading {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        @include mobile {
            row-gap: 8px;
            align-items: flex-start;
        }
    }
    &-title {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
        @include mobile {
            font-size: 24px;
            line-height: 32px;
                margin-inline: auto;
max-width: var(--content-wrapper-width);
        }
    }
    &-SubTitle {
        @include mobile {
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            margin-block-start: 8px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #464646;
        }
    }
    &-img{
        display: flex;
        overflow: scroll;
        width: 100%;
        @include mobile {
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            column-gap: 24px;
            margin-block-start: 8px;
        }
        @include desktop {
            gap: 60px;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
    &-Wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
            width: 120px;
            height: 120px;
            @include mobile {
                width: 80px;
                height: 80px;
            }
        }
        @include mobile {
            width: 104px;
        }
    }
    &-Text{
        width: min-content;
        text-align: center;
        margin-block-start: 16px;
        span{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #99854B;
        }
        @include mobile {
            width: 104px;
            span {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    &-link{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mobile {
                margin-inline: auto;
max-width: var(--content-wrapper-width);
            align-items: flex-start;
            row-gap: 24px;
            margin-block-start: 16px;
        }
        &-text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #464646;
            width: 75%;
            @include mobile{
                width: 100%;
                font-size: 12px;
                line-height: 20px;
            }
        }
        &-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 8px;
            border: 1px solid #99854B;
            @include desktop {
                width: 193px;
                height: 40px;
                margin-top: 36px;
            }
            span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #99854B;
            }
        }
    }
}
.ShopBy{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include desktop {
        //max-width: var(--content-wrapper-width);
        // min-height: 400px;
        padding-block: 40px;
    }
    @include mobile {
        background-size: 100%;
        // min-height: 320px;
        padding-block: 24px;
    }
    .slick-initialized .slick-slide {
        @include mobile {
            margin-right: 8px;
        }
    }
    .slick {
        &-slide{
            width: 232px;
            @include mobile {
                width: 170px;
            }
        }
        &-track {
            max-height: 330px;
            overflow: hidden;
            @include mobile {
                display: block;
            }
        }
    }
    &-title {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
        }
        @include mobile {
            padding-bottom: 24px;
            span {
                font-size: 24px;
                line-height: 26px;
            }
        }
    }
    &-slides{
        width: 100%;
        max-width: 95%;
        margin-inline: auto 0%;
        @include mobile {
            max-height: 240px;
            overflow: hidden;
        }
        .slick-slider, .slick-initialized {
            width: 100%;
        }
        ul {
            display: flex;
            flex-direction: row;
            overflow: scroll;
        }
    }
    &-Wrap{
        max-width: 222px;
        max-height: 320px;
        overflow: hidden;
        border-radius: 10px;
        padding-inline: 4px;
        img{
            width: 100%;
            height: 100%;
        }
        @include mobile {
            border-radius: 8px;
            // max-width: 154px;
            max-height: 240px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    &-Content{
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 16px;
        width: 100%;
        &-Name{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #FFFFFF;
            @include mobile {
                font-size: 14px;
                line-height: 17px;
            }
        }
        &-Content{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            font-size: 14px;
            line-height: 17px;
            background-color: #CEBC89;
            padding: 4px 8px;
            margin-block-start: 4px;
            margin-block-end: 11px;
            @include mobile {
                font-size: 10px;
                line-height: 12px;
            }
        }
        &-Button{
            &:hover{
                color: #FFFFFF;
                // outline: 1px solid #b53c37;
                box-shadow: 3px 3px 8px black;
            }
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            border: 1px solid white;
            padding: 8px 29px;
            @include mobile {
                padding: 6px 14px;
                font-size: 10px;
                line-height: 12px;             
            }
        }
    }
}
.OurFavourites {
    display: flex;
    flex-direction: column;
    padding-inline-start: 7%;
    padding-block: 40px;
    row-gap: 40px;
    @include desktop {
        height: 574px;
    }
    @media (max-width: 1200px) {
        padding-inline: 5%;
    }
    @include mobile {
        height: 320px;
    }
    &-title {
        align-self: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            @include mobile {
                font-size: 24px;
            }
        }

    }
    &-slides{
        display: flex;
        
        .slick-track {
            display: flex;
            flex-direction: row;
            overflow: scroll;
            column-gap: 24px;
            
            @include mobile {
                column-gap: 8px;
            }
        }
    }
    &-Content{
        display: flex!important;
        flex-direction: column;
        width: 232px!important;
        height: 302px;
        @include mobile {
            width: 138px!important;
            height: 183px;
        }
        &-Image {
            width: 100%;
            height: 100%;
        }
        &-Title{
            position: absolute;
            bottom: 0;
            background-color: #ffffff99;
            height: 44px;
            padding: 16px;
            width: 100%;
            overflow: hidden;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.05em;
            color: #000000;
            @include mobile {
                padding: 10px;
                height: 15px;
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
}
.LafzCombos {
    display: flex;
    flex-direction: column;
    padding-inline-start: 7%;
    padding-block: 40px;
    row-gap: 40px;
    @include desktop {
        height: 542px;
    }
    @media (max-width: 1200px) {
        padding-inline: 5%;
    }
    @include mobile {
        height: 360px;
    }
    &-title {
        align-self: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            @include mobile {
                font-size: 24px;
            }
        }

    }
    &-slides{
        display: flex;
        ul {
            display: flex;
            flex-direction: row;
            overflow: scroll;
            column-gap: 24px;
        }
    }
    &-Container{
        display: flex;
        flex-direction: column;
        width: 302px;
        height: 379px;
        &-Image {
            width: 300px;
            height: 300px;
        }
        @include mobile {
            width: 148px;
            height: 200px;
            &-Image {
                width: 148px;
                height: 148px;
            }
        }
    }
    &-Content{
        display: flex;
        flex-direction: column;
        text-align: start;
        padding: 16px;
        row-gap: 8px;
        @include mobile {
            padding: 9px;
            row-gap: 4px;
        }
    &-Title{
        height: 22px;
        overflow: hidden;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #000000;
        @include mobile {
            height: 15px;
            font-size: 12px;
            line-height: 15px;
        }
    }
    &-Text{
        height: 17px;
        overflow: hidden;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1em;
        color: #8B8B8B;
        @include mobile {
            height: 15px;
            font-size: 12px;
            line-height: 15px;
        }
    }
    }
}
.LafzCustomerSays {
    padding-block: 40px;
    @include mobile {
        margin-inline: auto;
        padding-block:24px;
    }
    &-Img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.4;
    }
    &-title {
        padding-bottom: 20px;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        color: #71333F;
        @include mobile {
            font-size: 20px;
            line-height: 27px;
            background-color: #FFFFFF;
            // border: 2px solid rgba(0, 0, 0, 0.08);
            border-bottom: none;
            padding-bottom: 20px;
        }
    }
    &-SubTitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        margin-block: 24px 40px;
        @include mobile {
            font-size: 14px;
            margin-block: 0;
            padding-block: 0 12px;
            background-color: #FFFFFF;
            // border-inline: 2px solid rgba(0, 0, 0, 0.08);
        }
    }
    &-slides {
        width: 100%;
        margin-inline: auto;
        //max-width: var(--content-wrapper-width);
        max-width: 95%;
        margin-inline: auto 0%;
        overflow: hidden;
        @include mobile {
            left: 0;
            margin-inline: auto auto !important;
        }
        .slick {
            &-slide {
                padding-inline-end: 24px;
                    @include mobile {
                        padding-inline-end: 0;
                    }
                }
            &-list {
                @include mobile {
                    border: 2px solid rgba(0, 0, 0, 0.08);
                    // border-top: none;
                }
            }
            &-dots {
                position: absolute;
                bottom: 35px;
                width: fit-content;
                left: 50%;
                transform: translateX(-50%);
                @include mobile {
                    transform: translateX(calc(-50%));
                    width: 90%;
                    max-width: 90%;
                    overflow: scroll;
                    white-space: nowrap;
                    position: relative;
                    margin-top: 16px;
                    bottom: 0;
                }
            }
        }
    }
    &-Container {
        background: #FFFFFF;
        border: 2px solid rgba(0, 0, 0, 0.08);
        /* margin-bottom: 95px; */
        display: flex !important;
        flex-direction: column;
        row-gap: 25px;
        padding: 24px;
        @include mobile {
            border: none;
            margin: 0;
        }
    }
    &-Review {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        &-Image {
            width: 82px;
            height: 82px;
            border-radius: 50%;
            @include mobile {
                width: 62px;
                height: 62px;
            }
        }
        &-Description {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            height: 14.2rem;
            @include mobile {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                overflow: hidden;
                display: -webkit-box; 
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                height: 60px;
                margin-top: 8px;
            }
        }
    }
    &-Customer {
        display: flex;
        column-gap: 12px;
        align-items: baseline;
        &-Name {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 15px;
            color: #000000;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 15px;
            @include mobile {
                font-size: 14px;
                line-height: 14px;
                height: 14px;
            }
        }
        &-Country {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        .CountryFlags {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            @include mobile {
                width: 16px;
                height: 16px;
            }
        }
    }
    &-Product {
        display: flex;
        column-gap: 15px;
        align-items: center;
        &-Image {
            width: 62px;
            height: 62px;
            border-radius: 50%;
            @include mobile {
                width: 62px;
                height: 62px;
            }
        }
        &-Name {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 2.2rem;
            @include mobile {
                font-size: 14px;
                line-height: 18px;
                height: 22px;
            }
        }
        &-Link {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            text-decoration: underline;
            @include mobile {
                font-weight: 500;
                font-size: 11px;
                line-height: 15px;
            }
        }
    }
}
.LafzBlog {
    //max-width: var(--content-wrapper-width);
    display: flex;
    flex-direction: column;
    padding-block: 40px;

    @include mobile {
        row-gap: 24px;
        padding-block: 24px;
    }

    &-title {
        padding-bottom: 20px;
        align-self: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
        }
        @include mobile {
            padding-bottom: 24px;
            span {
                font-size: 24px;
                line-height: 26px;
            }
        }

    }

    &-slides {
        max-width: 95%;
        margin-inline: auto 0%;
        //overflow: scroll;
        .slick-slider, .slick-initialized {
            width: 100%;
            max-height: 370px;
            overflow: hidden;
            @include mobile {
                max-height: 160px;
            }
        }
        
        .slick {
            &-slide{
                width: 372px;
                @include mobile {
                    width: 180px;
                }
            }
            &-track {
                display: flex;
                flex-direction: row;
                //overflow: scroll;
                column-gap: 24px;
                
                @include mobile {
                    column-gap: 8px;
                }
            }
        }
    }

    &-Container {
        display: flex!important;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        row-gap: 24px;
        width: 100% !important;

        @include mobile {
            width: 100% !important;
            row-gap: 8px;
        }
    }
    &-Image {
        width: 100%;
        max-height: 230px;
        overflow: hidden;

        @include mobile {
            width: 100%;
            height: 104px;
        }
    }
    &-Content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        @include mobile {
            row-gap: 8px;
        }
    }
    &-Title {
        height: 56px;
        overflow: hidden;
        letter-spacing: 0.05em;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 4.2rem;
        @include mobile {
            height: 64px;
            font-size: 12px;
            line-height: 16px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-height: 3.2rem;
        }
    }
    &-Description {
        height: 48px;
        overflow: hidden;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #464646;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 4.2rem;
        @include mobile {
            display: none;
            height: unset !important;
            font-size: 14px;
            line-height: 14px;
        }
    }
}
.HalalCertificates {
    display: flex;
    flex-direction: column;
    padding-block-start: 73px;
    padding-block-end: 65px;
    //background-color: #FAF6EB;
    @include desktop {
        align-items: center;
    }
    @include mobile {
        padding: 24px 5%;
    }
    &-Content {
        display: flex;
        flex-direction: column;
        row-gap: 14px;
        max-width: 560px;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            @include mobile {
                font-size: 24px;
                line-height: 32px;
                text-align: start;
            }
        }
        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #464646;
            @include mobile {
                font-size: 12px;
                line-height: 20px;
                text-align: start;
            }
        }
    }
    &-ul{
        display: flex;
        li {
            width: 100%;
            display: block;
            box-sizing: border-box;
            padding: 20px 15px;
            border-right: 1px solid #f3eeea;
        }
        @include desktop {
            max-width: 1102px;
            padding: 40px;
            align-items: center;
        }
        @include mobile {
            overflow: scroll;
            li {
                padding: 0%;
            }
        }
    }
    &-block{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
            width: 64px;
            height: 64px;
            margin: 20px 15px;
        }
    }
    img {
        max-width: 100px;
    }
}
.HomeBanner {
    height: 330px;
    background-color: #F8E2DE;
    @include mobile {
        display: none;
    }
    &-Content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        left: 20%;
        top: 17%;
        height: 190px;
        width: 409px;
        z-index: 2;
        span {
            height: 117px;
            width: 409px;
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #000000;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 8px;
            width: 131px;
            height: 40px;
            color: white;
            background: #000000;
        }
    }
    img{
        position: absolute;
        height: 100%;
        width: 67%;
        right: 0;
    }
}
.LastLine{
    height: 325px;
    padding-block: 80px;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    @media (max-width: 1200px) {
        padding-inline: 5%;
    }
    @include mobile {
        height: 220px;
        padding-block: 24px;
        //background-color: #FFFDF6;
            margin-inline: auto;
max-width: var(--content-wrapper-width);
    }

    &-title {
        align-self: center;
        margin-block-end: 40px;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
        }
        @include mobile {
            margin-block-end: 24px;
            span {
                font-size: 24px;
            }
        }
    }
    &-wrap{
        display: flex;
        flex-direction: row;
        @include desktop {
            justify-content: space-evenly;
        }
        @include mobile {
            overflow: scroll;
            padding: 0;
        }
    }
    &-Container {
        display: flex;
        align-items: center;
        column-gap: 16px;

        svg {
            width: 80px;
            height: 80px;
        }

        @include mobile {
            min-width: 116px;
            max-width: 116px;
            flex-direction: column;
            align-items: center;

            svg {
                width: 64px;
                height: 64px;
            }
        }
    }
    &-Name {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        @include mobile {
            padding-inline: 4px;
            text-align: center;
        }
    }
    &-Title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        @include mobile {
            font-size: 12px;
            line-height: 15px;
        }
    }
    &-Text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8B8B8B;
        @include mobile {
            font-size: 12px;
            line-height: 15px;
        }
        
    }
    &-Divider {
        border: 1px solid #CEBC89;
        @include mobile {
            border: 1px solid #E8E8E8;
        }
    }
}

.VideoCard {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    padding-top: 40px;
    padding-bottom: 44px;
    height: 648px;

    @include mobile {
        margin-inline: auto;
        max-width: var(--content-wrapper-width);
        padding-block: 24px;
        height: unset;
        padding-inline: 19px;

    }

    &-Background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.4;
    }

    &-title {
        align-self: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
        }
        @include mobile {
            padding-inline-end: 5%;
            span {
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    &-SubTitle {
        align-self: center;
        max-width: 552px;
        height: 34px;
        overflow: hidden;
        margin-block-start: 24px;
        text-align: center;
        span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }
        @include mobile {
            margin-block-start: 16px;
            height: 30px;
            padding-inline-end: 5%;
            span {
                font-size: 12px;
                line-height: 15px;
            }
        }
    }

    &-slides{
        margin-block-start: 47px;
        display: flex;
        .slick-slider, .slick-initialized {
            width: 100%;
        }
        &ul {
            display: flex;
            flex-direction: row;
            overflow: scroll;
            width: 100%;
            gap: 20px;
            flex-wrap: nowrap;
            li {
                margin-left: 17px;
                @include mobile {
                    flex: 0 0 40%;
                }
                &:first-child {
                    margin-left: auto;
                }
                &:last-child {
                    margin-right: auto;
                }
            }
        }
        @include mobile {
            margin-block-start: 24px;            
        }
    }

    &-Wrap {
        width: 100%;
        min-width: 198px;
        max-width: 250px;
        height: 396px;
        border-radius: 8px;
        background-color: white;
        border-radius: 6px;
        overflow: hidden;
        margin: auto;
        @include mobile {
            width: 100%;
            max-width: 160px;
            height: 353px;
            filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));            
            margin-bottom: 5px;
            margin-left: 3px;
        }
        .VideoCard-Price-PriceValue {
            font-size: 15px;
        }
        .VideoCard-Price-HighPrice{
            font-size: 13px;
        }
    }
    &-Img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        @include mobile {
            height: 248px;
        }
    }
    &-ProductImg {
        position: absolute;
        width: 48px;
        height: 48px;
        padding: 4px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        z-index: 5;
        top: -24px;
        align-self: center;
    }
    &-Content {
        position: absolute;
        display: flex;
        flex-direction: column;
        margin-inline: 10px;
        inset-block-end: 10px;
        padding: 10px 13px;
        background-color: white;
        width: -webkit-fill-available;
        align-items: center;
        @include mobile {
            position: relative;
            margin: 0px;
            padding-top: 25px;
        }
    }
    &-Name {
        margin: 25px 0 0 0;
        height: 34px;
        overflow: hidden;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #000000;
        @include mobile {
            height: 24px;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
        }
    }

    &-Rating {
        display: flex;
        align-items: center;
        margin-block-start: 10px;
        @include mobile {
            margin-block-start: 2px;
        }

        &-card {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 7px;
            padding: 4px;
            background-color: #F0F0F0;
            border-radius: 20%;
            width: 52px;
            height: 22px;

            span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #000000;
            }

            svg {
                inset-inline-end: 0;
            }

            @include mobile {
                margin-block-start: 0px;
            }
        }

        &-review {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #161616;
            align-self: center;
            padding-inline-start: 8px;
        }
    }

    &-Price {
        margin-block-start: 8px;
        overflow: scroll;
        &-HighPrice {
            opacity: 1;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            text-decoration-line: line-through;
            color: #8B8B8B;
            margin-right: 12px;

            @include mobile {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &-PriceValue {
            margin-top: 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #000000;

            @include mobile {
                font-size: 13px;
                line-height: 17px;
            }
        }
        @include mobile {
            margin-block-start: 2px;
        }
    }
    &-Product {
        &-Container {
            display: flex;
            max-height: 633px;
            max-width: 725px;
            min-width: 574px;
            overflow: scroll;
            @include mobile {
                flex-direction: column;
                height: 100%;
                width: 100%;
                position: fixed;
                top: 0;
                left: 0;
                max-height: unset;
                max-width: unset;
                min-width: unset;
            }
            li {
                margin-bottom: 0!important;
            }
        }
        &-Img {
            height: 100%;
            width: 100%;
            background: var(--color-black);
            object-fit: cover;
            max-height: 633px;
            @include mobile {
                height: auto;
                background: none;
            }
        }
        .slick-slider, .slick-initialized {
            width: 100%;
        }
    }
}

.Modal-Content {
    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
    }
}