/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;    
    z-index: 100;
    &-Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
    }
    &-Close {
        position: absolute;
        top: 30px;
        right: 30px;
        color: var(--color-white);
        @include mobile {
            top: 5px;
            right: 5px;
            z-index: 1;
            background: rgba(0,0,0, 0.4);
            border-radius: 7px;
        }
        svg {
            fill: var(--color-white);
            width: 50px;
            height: 50px;
        }
    }
    &-Content {
        // width: 50%;
        // height: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow-y: scroll;
        overflow-x: hidden;   
        background: var(--color-white);
        // @include mobile {
        //     width: 90%;
        //     height: 84%;
        // }
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
}
