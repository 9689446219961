/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */

.BackIcon {
    fill: var(--color-black);
    cursor: pointer;

    // &_direction {
        // &_left {
        //     transform: rotate(0deg);

        //     [dir="rtl"] & {
        //         transform: none;
        //     }
        // }

        // &_right {
        //     [dir="rtl"] & {
        //         transform: rotate(180deg);
        //     }
        // }

        // &_bottom {
        //     transform: rotate(90deg);
        // }

        // &_top {
        //     transform: rotate(-90deg);
        // }
    // }
}
