/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.SkinAnalyser {
    --color-blue: #175883;
    --color-bg-light: #E4CBCA;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-shadow: #7A7373;
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    background-color: #E4CBCA;
    background-image: url(https://www.lafz.com/media/wysiwyg/1671526626521.JPEG);
    background-position: center;
    background-size: cover;
    @include mobile  {
        padding: 20px;
        margin-top: 90px;
    }
    &-Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
    }
    &-SubHeading, &-Heading {
        color: var(--color-blue);
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;
        margin-bottom: 20px;
        @include mobile {
            font-size: 20px;
        }
    }
    &-MainHeading {
        color: var(--color-blue);
        text-transform: uppercase;
        font-size: 30px;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 900;
    }
    &-Divider {
        height: 1px;
        background-color: var(--color-blue);
        width: 100%;
    }
    &-TextItalic, &-Text {
        margin-top: 20px;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        @include mobile {
            font-size: 17px;
            margin-bottom: 30px;
        }
    }
    &-TextItalic {
        font-style: italic;
        color: var(--color-blue);
        text-transform: unset;
    }
    &-Button {
        position: relative;
        margin: 0 6px 6px 0;
        display: inline-block;
        min-width: 200px;
        a, button {
            background-color: var(--color-blue);
            color: var(--color-white);
            text-transform: uppercase;
            border-radius: 5px;
            padding: 10px 15px;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 1px;
            width: 100%;
            border: 1px solid var(--color-blue);
            transition: all 0.3s ease-in-out;
            display: inline-block;
        }
        span {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 100%;
            height: 100%;
            background: var(--color-white);
            border: 1px solid var(--color-blue);
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
        }
        &:hover {
            a {
                text-decoration: none;
            }
            button {
                background: #ffffff;
                color: var(--color-blue);
            }
            span {
                background-color: var(--color-blue);
            }
        }
    }
    &-BackButton {
        background-color: var(--color-white);
        color: var(--color-blue);
        text-transform: uppercase;
        border-radius: 5px;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 1px;
    }
    &-Input {
        border: 1px solid var(--color-blue);
        // border-top: 0;
        width: 100%;
    }
    &-RadioButtons {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        counter-reset: a;
        row-gap: 10px;
        column-gap: 25px;
    }
    &-RadioButton {        
        list-style-type: upper-alpha;
        border: 1px solid var(--color-blue);
        border-radius: 50px;
        padding: 0;
        min-width: 150px;
        min-height: 35px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        padding-inline-start: 35px;
        overflow: hidden;
        text-align: center;
        margin-bottom: 0;
        @include mobile {
            min-width: 250px;
        } 
        &:last-child{
            margin-bottom: 0 !important;
        }
        &::before {
            counter-increment: a;
            content: counter(a, upper-alpha);
            position: absolute;
            top: 0;
            left: 0 !important;
            height: 35px;
            background: var(--color-blue);
            border-radius: 50%;
            width: 35px;
            padding-right: 0 !important;
            color: var(--color-white);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
        }
        &:hover, &_isSelected {
            color: var(--color-white);
            background-color: var(--color-blue);
            cursor: pointer;
        }
    }
    &-Step {
        border: 1px solid var(--color-blue);
        background: var(--color-white);
        padding: 20px;
        border-radius: 5px;
        box-shadow: 5px 5px 6px 0px var(--color-shadow);
        min-width: 600px;
        min-height: 300px;
        max-width: 800px;
        @include mobile {
            min-width: unset;
        }
    }
    &-StepOne {
        display: flex;
        gap: 30px;
        @include mobile {
            flex-direction: column;
        } 
        .ImageWrapper {
            max-width: 300px;
            min-width: 250px;
            @include mobile {
                max-width: 100%;
            }
        }
        .GettingStarted {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            &-Action {
                text-align: center;
            }
            &-TextSmall {
                text-align: center;
                font-size: 18px;
                color: var(--color-blue);
                margin-top: 20px;
            }
        }
    }
    &-StepTwo {
        max-width: 610px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-inline: 100px;
        @include mobile {
            padding-inline: 0;
        }
        .SkinAnalyser-Text {
            margin-bottom: 30px;
        }
        .SkinAnalyser-Input {
            margin-bottom: 30px;
        }
        .SkinAnalyser-Button {
            margin-bottom: 20px;
        }
    }
    &-StepThree, &-StepFour {
        padding-inline: 100px;
        @include mobile {
            padding-inline: 0;
        }
        &Inputs {
            margin: 30px;
            @include mobile {
                margin-inline: 0;
            }
            ol {
                grid-template-columns: repeat(2, 1fr);
                @include mobile {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
        &Actions {
            text-align: center;
        }
    }
    &-StepFive {
        padding-inline: 100px;
    }

    &-StepSix {
        text-align: center;
        .SkinAnalyser-Text {
            margin-bottom: 9px;
        }
        .SkinAnalyser-Input {
            margin-top: 0;
        }
        .SkinAnalyser-Button {
            margin-top: 16px;
        }
    }

    &-DynamicStep {
        &Content {
            display: flex;
            gap: 20px;
            @include mobile {
                flex-direction: column;
            }
        }
        &Inputs {
            flex: 1;
        }
        .SkinAnalyser-DynamicStepActions {
            text-align: center;
        }
        .SkinAnalyser-Text {
            font-size: 14px;
            margin-bottom: 40px;
            text-transform: uppercase;
        }
    }

    &-VerticalDivider {
        width: 1px;
        background-color: var(--color-blue);
    }

    &-CurrentQuestion {
        flex: 1;
    }

    &-image {
        border: 1px solid var(--color-blue);
        padding: 15px;
        &-Image {
            flex: 1;
        }
        &-Content {
            flex: 1.5;
        }
        &-Label {
            color: var(--color-blue);
            text-transform: uppercase;
            font-size: 18px;
            text-align: center;
            margin-bottom: 30px;
        }
        .rangeslider {
            max-width: 4px;
            background-color: var(--color-blue);
            @include mobile {
                max-width: unset;
            }
            &-horizontal {
                height: 4px;
            }
            &__fill {
                background-color: var(--color-blue);
            }
            &__handle {
                width: 24px;
                height: 24px;
                left: -10px;
                border-radius: 50%;
                background-color: var(--color-blue);
                &::after {
                    @include mobile {
                        content: none;
                    }
                }
            }
        }
    }
    &-HelpText {
        font-style: italic;
        text-align: center;
        font-size: 17px;
        margin-bottom: 10px;
    }
}
