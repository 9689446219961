/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.HomePage {
    &-SaleTimer {
        // background: #f7d1ff;
        display: flex;
        align-items: center;
        justify-content: center;
        &Inner {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: var(--content-wrapper-width);
            padding: 20px 45px;
            // background: linear-gradient(45deg, #8DE388, #EBD84E, #74DBE5);
            //background-color: #00774F;
            @include mobile{
                padding: 12px 20px;
            }
        }
        &Content {
            display: flex;
            align-items: center;
            &Heading {
                flex: 1;
                //color: #CCB715;
                font-size: 43px;
                text-transform: uppercase;
                font-weight: 700;
                @include mobile {
                    font-size: 20px;
                }
            }
            &TimeLeft {
                flex: 1;
                display: flex;
                justify-content: space-around;
                gap: 10px;
                &Heading {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    div {
                        //color: #CCB715;
                        &:nth-child(1) {
                            font-size: 17px;
                            line-height: 17px;
                            text-transform: uppercase;
                            font-weight: 500;
                            @include mobile {
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }
                        &:nth-child(2) {
                            font-size: 35px;
                            line-height: 35px;
                            font-weight: 700;
                            @include mobile {
                                font-size: 24px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}