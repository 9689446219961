/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

.Menu {
    &-SkinAnalyserLink {
        margin-inline-end: 10px;
        margin-block-end: 5px;
        display: inline-block;
        background-color: #71333f;
        color: #ffffff;
        padding: 5px 10px;
        border-radius: 5px;
        &Wrapper {
            background-color: #ffffff;
            text-align: right;
            // padding-inline-end: 10px;
        }
    }
}