/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.HomePage {
    @include mobile {
        margin-top: calc(var(--header-total-height) + var(--search-bar-height));
    }
    .CmsPage {
        &,
        &-Wrapper {
            margin-block-end: 0;
        }
    }

    .best-seller-products {
        .ProductListWidget {
            @include mobile {
                overflow-x: scroll;
            }
        }
        .ProductListWidget-Page {
            @include mobile {
                display: flex;
                flex-wrap: nowrap;
            }
            .ProductCard {
                @include mobile {
                    flex: 0 0 40%;
                    &-AddToCartLink, .AddToCart {
                        font-size: 11px;
                        padding: 7px 5px;
                        .CartIcon {
                            display: none;
                        }
                    }
                }                
            }
        }
    }
    .shop-by-category {
        .collection-item {
            @include mobile {
                flex: 1 0 calc(50% - 20px);
                padding: 0;
                border-radius: 10px;
                overflow: hidden;
                margin-inline: 5px;
                margin-bottom: 10px;
                .catagory-block .catagory-txt>span {
                    font-size: 11px !important;
                }
            }
        }
        &-heading {
            @include mobile {
                margin-top: 30px;
            }
        }
    }

    .shop-by-concern {
        .show-by-concern-item {
            @include mobile {
                overflow-x: scroll;
            }
            .row {
                @include mobile {
                    flex-wrap: nowrap;
                }
                .collection-item {
                    @include mobile {
                        flex: 1 0 70%;
                        padding-inline: 0 10px;
                    }
                }
            }
        }
    }
}
