/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.TierPrices {
    width: 100%;
    margin-block-start: 15px;

    &-Item {
        font-size: 14px;
        padding-inline-start: 0;

        &:last-child {
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }

        &::before {
            content: none;
        }

        &_isLowest {
            color: var(--secondary-dark-color);
            font-size: 10px;
            margin-block-end: 0;
            line-height: 1.8;
        }
    }

    &-ItemPrice {
        font-weight: bold;
    }
}
