/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import 'abstract/variables';
@import 'abstract/media';
@import 'abstract/button';
@import 'abstract/loader';
@import 'abstract/icons';
@import 'abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import './abstract/abstract';
@import './base/breakpoints';
@import './base/keyframes';
@import './base/root';
@import './base/reset';
@import './base/button';
@import './base/list';
@import './base/link';
@import './base/table';
@import './base/input';
@import './base/fieldset';
@import './base/blockquote';
@import './cms/block';

@import './base/font';

body.isLocal {
    iframe {
        display: none;
    }
}
:root{
    --global-gradient-to-left: linear-gradient(to left, #bb7659, #eed0cb, #bb7659);
    --color-yellow: #CEBC89;
    --color-light-yellow: #F1E2BF;
    --color-blue: #2651A4;
    --color-white: #ffffff;
    --color-brown: #71333F;
    --color-light-brown: #F0E7E2;
}