div[data-content-type='video'] {
    font-size: 0;

    .pagebuilder-video-inner {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
    }

    .pagebuilder-video-container {
        border-radius: inherit;
        overflow: hidden;
        position: relative;
    }

    iframe,
    video {
        height: 100%;
        min-height: 400px;
        width: 100%;
    }
}
