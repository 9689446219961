/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

.OrboWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0, 0.8);
    left: 0 !important;
    top: 0 !important;
    display: none;
    z-index: 9999;
    &_isOpen {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &-Video {
        position: relative;
    }
    &-Options {
        position: absolute;
        bottom: 0;
        padding: 10px;
        background: rgba(0,0,0, 0.3);
        width: 100%;
        .ProductConfigurableAttributes-ExpandableContentHeading, .ProductConfigurableAttributes-ExpandableContentSubHeading {
            color: var(--color-white);
        }
        .Field input {
            background-color: var(--color-white);
        }
        .ProductConfigurableAttributes-SwatchList {
            gap: 10px;
        }
        @include mobile {
            .ProductConfigurableAttributes-SwatchList {
                justify-content: flex-start;
            }
            .ProductAttributeValue-Color_isPDP {
                min-height: 35px;
                width: 35px !important;
            }
            .ProductAttributeValue-Color_isPDP::after {
                height: 10px !important;
                width: 17px !important;
                top: 10px !important;
                left: 8px !important;
            }
            .ProductConfigurableAttributes-ExpandableContentHeading {
                margin-top: 5px;
            }
        }
    }
    &-CloseButton {
        position: absolute;
        top: 10px;
        right: 40px;
        color: #ffffff;
        font-size: 30px;
        font-weight: 600;
        .CloseIcon {
            fill: #ffffff;
            cursor: pointer;
            width: 40px !important;
            height: 40px !important;
        }
    }
    &-ProductTitle {
        position: absolute;
        top: -31px;
        left: 0px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        z-index: 1;
        background: #000000;
        width: 100%;
        padding: 10px;
    }
    .ProductConfigurableAttributes {
        &-OptionLabel, &-SelectedOptionLabel {
            color: var(--color-white);
        }
        &-SwatchList {
            flex-direction: row;
        }
    }
}
