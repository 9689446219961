/* stylelint-disable */
@mixin grey-page-bg {
    @include mobile {
        background: var(--secondary-base-color);
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: var(--secondary-base-color);

        @include after-mobile {
            content: none;
        }
    }
}

@mixin global-heading {
    font-size: 40px;
    font-weight: 300;
    color: var(--color-brown);
    text-transform: uppercase;
    text-align: center;
    .italic, em, span {
        font-weight: 600;
    }
}

@mixin global-page-title {
    font-size: 40px;
    font-weight: 300;
    color: var(--color-brown);
    text-transform: uppercase;
    text-align: center;

    .italic, em, span {
        font-weight: 600;
    }
}

@mixin splideArrow($position: topRight) {
    .splide__arrows {
        .splide__arrow {
            background-color: #EDE7E0;
            width: 40px;
            height: 40px;
        }

        @if ($position == topRight or $position == bottomRight) {
            position: absolute;
            right: 0;
            .splide__arrow--next { 
                right: 5px;
            }
            .splide__arrow--prev { 
                right: 60px;
                left: auto;
            }
        }

        @if $position == topRight {
            top: -35px;
        }

        @if $position == bottomRight {
            bottom: -35px;
        }
    }
}
@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 20px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}

@mixin description-tags {
    li,
    span,
    div,
    p,
    dt,
    dd,
    dl {
        @content;
    }
}

@mixin shadow-card {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

@mixin mobile-bottom-wrapper {
    @include mobile {
        @content;

        border-block-start: 1px solid var(--primary-divider-color);
        position: fixed;
        inset-inline-start: 0;
        width: 100%;
        z-index: 85;
        background-color: var(--color-white);
        padding-block-end: var(--footer-total-height);
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

        .hideOnScroll & {
            transform: translateY(var(--footer-nav-height));
        }
    }
}
