/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: var(--secondary-light-color);
    --navigation-tabs-color: var(--secondary-dark-color);
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-icon-stroke-width: 2px;

    position: fixed;
    inset-block-end: 0px;
    inset-inline-start: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-block-end: env(safe-area-inset-bottom);
    // transform: translateY(0);
    // transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    // .hideOnScroll & {
    //     transform: translateY(100%);
    // }

    @include mobile {
        border-block-start: 1px solid var(--primary-divider-color);
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 12px;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;

        @include mobile {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 10px;
            gap: 10px;
            width: 100%;
            height: 50px;
            background-color: #71333F;
            box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.25);
        }
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        //gap: 5px;
        //padding-block: 7px;
        div.Image {
            width: 24px;
            height: 24px;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
            }
        }
        >span {
            font-family: 'PP Pangram Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: rgba(255, 255, 255, 0.4);
        }
    }
    &-Button_isActive {
        span {
            color: #EED899;
        }
    }

    &-Icon {
        font-weight: bold;
    }
}
