/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


.CmsPage {
    &_isBreadcrumbsHidden {
        margin-top: calc(var(--header-total-height) + 1.2rem);

        @include mobile {
            margin-top: calc(var(--header-total-height) + 1.4rem);
        }
    }
    &-Heading{
        display: none;
    }

    &-Content {
        ul,
        ol {
            margin-top: 1rem;
        }

        /* FAQ Page */
        .Faq-Wrapper {
            padding-inline: 12px;
            .mainTitle {
                @include global-heading;
                text-transform: capitalize;
                text-align: center;
                @include mobile {
                    font-size: 32px;
                }
            }
            .blockTitle {
                font-family: 'Playfair Display';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 43px;
                color: #71333F;
                text-transform: uppercase;
                text-align: left;
                margin-block: 60px 0;
                @include mobile {
                    font-size: 20px;
                    margin-block: 30px 0;
                }
            }
            .expand {
                &-row {
                    padding: 17px 5px;
                    border-bottom: 1px solid #C9C5C5;
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-inline-start: 10px;
                    @include mobile {
                        padding: 17px 5px;
                        margin-inline-start: 5px;
                    }
                }
                &-title {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding-right: 20px;
                    font-weight: 600;
                    text-align: left;
                    &::after {
                        position: absolute;
                        content: '+';
                        font-size: 28px;
                        color: #5f5f5f;
                        left: auto;
                        right: 0;
                    }
                    &.expanded {
                        margin-bottom: 15px;
                        &::after {
                            content: '';
                            border: 1px solid #5f5f5f;
                            width: 12px;
                        }
                    }
                    @include mobile {
                        font-size: 14px;
                    }
                }
                &-content {
                    padding: 0 18px;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.2s ease-out;
                    font-size: 14px;
                    color: #585757;
                    font-family: 'Montserrat';
                    line-height: 18px;
                    max-width: 95%;
                    @include mobile {
                        font-size: 12px;
                        max-width: 100%;
                        padding: 0 8px 0 4px;
                    }
                }
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);

        padding: {
            left: 1.2rem;
            right: 1.2rem;
        };

        margin: {
            top: calc(var(--header-total-height) + 1.2rem);
            bottom: var(--header-nav-height);
            left: auto;
            right: auto;
        };

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 1.4rem;
            }
            margin: {
                top: calc(var(--header-total-height) + 1.4rem);
            }
        }

        @include desktop {
            margin-top: 2.4rem;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }

    &-Heading {
        margin-bottom: 1.8rem;

        @include mobile {
            margin-bottom: 2.1rem;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0 0 10px;
        padding-bottom: 30%;

        @include mobile {
            padding-bottom: 60%;
        }

        &:first-child {
            padding-bottom: 40%;

            @include mobile {
                padding-bottom: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-bottom: 2.16rem;
        display: block;

        @include mobile {
            margin-bottom: 2.52rem;
        }
    }

    h2,
    h3 {
        @include mobile {
            font-size: 1.4rem;
        }
    }
}

#belief-page {
	p {
        font-size: 17px;
        font-weight: 400;
        line-height: 1.5;
        @include mobile{
            font-size: 16px;
        }
	}
	.banner-wrapper {
		position: relative;
        h1{
            font-size: 5.5em;
            font-family: "Ogg";
            font-weight: 400;
            @include mobile{
                font-size: 33px;
            }
        }
		.content-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: #ceac87;
            display: flex;
            align-items: center;
            padding: 7% 9%;
            @include mobile{
                padding: 0% 9%;
            }
		}
		.Image_hasSrc {
			padding: 0;
			overflow: auto;
			height: auto;
			img {
				width: 100%;
				height: auto;
				padding: 0;
				position: static;
                @include mobile{
                    min-height: 300px;
                    object-fit: cover;
                    object-position: 19% top;
                }
			}
		}
	}
	h3.title {
        font-size: 48px;
        font-family: "Ogg";
        text-align: center;
        margin-bottom: 40px;
        font-weight: 300;
        //background-image: url(../HomePage/icons/Halal-line-01-01.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
		em {
			color: #d1ad82;
			font-family: "Ogg-italic";
		}
        @include mobile{
            font-size: 33px;
            margin-bottom: 20px;
        }
	}
    .choose-wrapper{
        padding: 50px 0;
        text-align: center;
        h4{
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 50px;
            padding-bottom: 30px;
            @include mobile{
                padding-bottom: 0;
                margin-bottom: 20px;
                font-size: 20px;
            }
        }
        .box-wrapper {
            padding: 10px;
            border: 1px solid #cfc8c1;
            border-radius: 10px;
            ul{
                background: #faf7f4;
                list-style: none!important;
                padding: 15px;
                border-radius: 10px;
                margin: 0;
                li{
                    padding-left: 0;
                    font-size: 22px;
                    line-height: 1.7;
                    padding-bottom: 10px;
                    &::before{
                        position: static;
                    }
                    @include mobile{
                        font-size: 18px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
            .main {
                width: 100px;
                height: 20px;
                position: absolute;
                display: block;
                top: -9px;
                margin: auto;
                left: 0;
                right: 0;
                .left-dot,.right-dot{
                    width: 12px;
                    height: 12px;
                    border: 1px solid #cfc8c1;
                    display: inline-block;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    margin-top: 3px;
                    z-index: 9;
                }
                .middle-dot {
                    position: absolute;
                    border-top: 1px solid #fefcfb;
                    top: 8px;
                    display: block;
                    height: 1px;
                    width: 100%;
                    z-index: 1;
                }
                .right-dot{
                    left: auto;
                    right: 0;
                }
            }
        }
    }
    .inspire-wrapper{
        .Image_hasSrc{
            padding-bottom: 0;
            height: auto;
            overflow: auto;
            text-align: center;
            img{
                width: 100%;
                height: auto;
                position: static;
                max-width: 200px;
                border-radius: 50%;
                min-height: 200px;
                object-fit: cover;
            }
        }
        .title{
            @include desktop{
                margin-bottom: 60px;
            }
        }
        h4{
            font-size: 28px;
            font-weight: 300;
            margin: 10px 0;
            text-align: center;
        }
        p{
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
            text-align: center;
            @include desktop{
                padding: 0 7%;
                line-height: 1.8;
            }
            @include mobile{
                margin-bottom: 30px;
            }
        }
    }
    .HalalCertificates{
        margin: 100px auto;
        width: 900px;
        @include mobile{
            width: 100%;
            margin: 40px auto;
        }
        .title{
            background-size: 85%;
        }
        &-Desc{
            text-align: center;
            font-size: 20px;
        }
        &-Row{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 50px;
            @include mobile{
                margin-top: 20px;
            }
        }
        &-Column{
            padding: 36px;
            @include mobile{
                padding: 12px;
            }
            .Image{
                padding-bottom:0;
                height: 110px;
                width: 110px;
                &-Image{
                    position: initial;
                }
                @include mobile{
                    height: 68px;
                    width: 68px;
                }
            }
            // @include desktop{
                &:nth-child(1), &:nth-child(2), &:nth-child(3){
                    border-right: 1px solid #e9e4df;
                    border-bottom: 1px solid #e9e4df;
                }
                &:nth-child(4){
                    border-bottom: 1px solid #e9e4df;
                }
                &:nth-child(5), &:nth-child(6){
                    border-right: 1px solid #e9e4df;
                }
            // }
        }
    }
}

.CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        /*padding-inline: 32px;*/
        margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;

        @include mobile {
            /*padding-inline: 14px;*/
            margin-block-start: 0;
        }

        @include desktop {
            margin-block-start: 0;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block: 16px 40px;

        @include mobile {
            margin-block: 5px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
        }
    }

    .widget {
        overflow-x: auto;
    }

    ul,
    ol {
        // margin-inline-start: 20px;

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }
    }
}

.ContentWrapperHalf {
    grid-template-columns: auto;
    max-width: var(--content-wrapper-width);
    /*padding-inline: 32px;*/
    margin-block-start: calc(var(--header-total-height) + 12px);
    margin-block-end: var(--header-nav-height);
    margin-inline: auto;

    @include mobile {
        /*padding-inline: 14px;*/
        margin-block-start: 0;
    }

    @include desktop {
        margin-block-start: 0;
    }

    &_page_width {
        &_full {
            max-width: 100%;
            padding-inline: 0;
        }
    }
}