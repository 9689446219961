/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.VideoSliderSlide {
    &-Modal {
        display: flex;
        max-width: 680px;
        @include mobile {
            width: 100vw;
            height: 100vh;
            display: block;
        }
        video {
            flex: 0 0 30%;
            @include mobile {
                width: 100vw;
                height: 100vh;
                object-fit: cover;
            }
        }
        > div {
            padding: 10px;
            flex: 1;
            @include mobile {
                position: absolute;
                bottom: 0;
                height: 100%;
                flex: 0 0 100%;
                width: 100%;
            }
        }
        .ProductCard {
            height: 100%;
            justify-content: space-between;
            @include mobile {
                justify-content: flex-end;
            }
            &-Link {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
            }
            &-FigureReview {
                width: 90px;
                padding: 0;
                @include mobile {
                    width: 53px;
                }
            }
            &-Name {
                width: unset;
                @include mobile {
                    color: var(--color-white);
                }
            }
            &-PriceWrapper{
                position: absolute;
                left: 100px;
                top: 50px;
                @include mobile {
                    left: 64px;
                    top: 35px;
                    .ProductPrice-PriceValue {
                        color: var(--color-white);
                    }
                }
            }
            &-Description {
                margin-top: 10px;
                @include mobile {
                    display: none;
                }
            }
            &-SaleBadge {
                left: 0;
                right: auto;
                @include mobile {
                    display: none;
                }
            }
        }
    }
}
