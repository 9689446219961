/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* stylelint-disable */ 
.CmsBlock {
    &-Wrapper_type {
        &_header_cms {
            order: 3;
        }
    }
}

.pagebuilder-overlay {
    p {
        margin-block-end: 0;
    }

    img {
        margin-block-end: -5px;
    }
}

.show-mobile {
    display: none !important;
}

.show-desktop {
    display: block !important;
}

@include mobile {
    .show-desktop {
        display: none !important;
    }

    .show-mobile {
        display: block !important;
    }
}