/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.CategoriesPopup {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 101;
    height: calc(100vh - 52px);
    &-Overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
    }
    &-Content {
        position: absolute;
        background: var(--color-white);
        bottom: 0;
        width: 100%;
        min-height: 50%;
        max-height: 83%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    &-CloseButton {
        position: absolute;
        top: 16px;
        right: 8px;
        z-index: 1;
    }
    &-BackButton {
        position: absolute;
        top: 16px;
        left: 8px;
        z-index: 1;
    }
    &-Heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        border-bottom: 1px solid rgb(238, 238, 238);
        text-align: center;
        padding: 0 37px;
        height: 54px;
        box-sizing: border-box;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        line-height: 1.25;
        font-weight: 700;
        font-size: 16px;
        margin: 0px;
    }
    &-TopLevel {
        overflow-y: auto;
        line-height: 1.25;
        flex: 1 1 0%;
        display: block;
        padding: 0 2px 32px;
        list-style: none;
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(2, 1fr);
        margin-inline: 16px;
        margin-block: 16px 0;
    }
    &-TopLevelItem {
        display: flex;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        // padding: 16px;
        border-radius: 8px;
        font-weight: 700;
        height: 80px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px;
        border: none;
        background-color: #FFF;
        box-sizing: border-box;
        color: #000;
        text-decoration: none;
        &Heading {
            font-size: 14px;
            padding: 17px 9px;
        }
        &Image {
            min-width: 80px;
            img {
                object-fit: contain;
                width: 80px;
                height: 80px;
            }
        }
    }
    .skincare {
        background-color: #fef5d6;
    }
    .haircare {
        background-color: #e5d0d5;
    }
    .makeup {
        background-color: #ffe2d0;
    }
    .sale {
        background-color: #d0f1ff;
    }
    .combo {
        background-color: #d0ffef;
    }
    .combos {
        background-color: #d0ffef;
    }
    .skinanalyzer {
        background-color: #ffe1dc;
    }
    &-MenuListItem {
        margin: 0;
        &::before {
            content: none;
        }
        a {
            color: var(--color-black);
            font-weight: 400;
            padding-left: 15px !important;
            padding-block: 13px;
            display: flex;
            border-top: 1px solid #ededed;
        }
    }
    &-MenuList {
        max-height: 500px;
        overflow-x: scroll;
        padding: 0;
        padding-left: 0 !important;
        >li{
            border-bottom: 1px solid #dedede;
            padding: 12px;
        }
    }
    &-MenuListSub {
        display: none;
        margin-top: 16px;
    }
    &-MenuListItemInput{
        display: none;
        &::after {
            content: '+';
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &-MenuListItemInput:checked ~  &-MenuListSub {
        display: block;
    }
    &-MenuListItemInputLabel {
        &::after {
            content: '+';
            position: absolute;
            right: 0;
            font-size: 23px;
        }
    }
    &-MenuListItemInput:checked ~ &-MenuListItemInputLabel {
        &::after {
            content: '-';
        }
    }
    .makeup_category {
        background-color: #dcffea;
    }
    .skin_category {
        background-color: #fff5cb;
    }
    .brands_category {
        background-color: #ffe3ff;
    }
    .personalcare_category {
        background-color: #d0efff;
    }
    .wellness_hygiene_category {
        background-color: #f8d8ee;
    }
    .combos_category {
        background-color: #f8ebd7;
    }
    .sale_category {
        background-color: #fbf3b6;
    }
    .concern_category{
        background-color: #ffe1e5;
    }
}
