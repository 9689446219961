/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.NpsSurvey {
    --color-blue: #175883;
    --color-bg-light: #E4CBCA;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-shadow: #7A7373;
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    background-color: #E4CBCA;
    background-image: url(https://www.lafz.com/media/wysiwyg/1671526626521.JPEG);
    background-position: center;
    background-size: cover;
    @include mobile  {
        padding: 20px;
        margin-top: 90px;
    }
    &-Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
    }
    &-ActiveStep, &-SuccessMessage {
        border: 1px solid var(--color-blue);
        background: var(--color-white);
        padding: 20px;
        border-radius: 5px;
        box-shadow: 5px 5px 6px 0px var(--color-shadow);
        min-width: 600px;
        min-height: 300px;
        max-width: 800px;
        @include mobile {
            min-width: unset;
            width: 100%;
        }
    }
    &-SuccessMessage {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        justify-content: flex-start;
    }
    &-Heading {
        color: var(--color-blue);
        font-size: 28px;
        text-align: center;
        margin-bottom: 20px;
        @include mobile {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    &-SubHeading {
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        @include mobile {
            font-size: 17px;
        }
            
    }
    &-Divider {
        height: 1px;
        background-color: var(--color-blue);
        width: 100%;
    }
    &-Button {
        position: relative;
        margin: 0 6px 6px 0;
        display: inline-block;
        min-width: 135px;
        &:hover {
            cursor: pointer;
        }
        a, button {
            background-color: var(--color-blue);
            color: var(--color-white);
            text-transform: uppercase;
            border-radius: 5px;
            padding: 10px 15px;
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 1px;
            width: 100%;
            border: 1px solid var(--color-blue);
            transition: all 0.3s ease-in-out;
            display: inline-block;
            &:hover {
                cursor: pointer;
            }
        }
        span {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 100%;
            height: 100%;
            background: var(--color-white);
            border: 1px solid var(--color-blue);
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
        }
        &:hover {
            a {
                text-decoration: none;
            }
            button {
                background: #ffffff;
                color: var(--color-blue);
            }
            span {
                background-color: var(--color-blue);
            }
        }
        @include mobile {
            min-width: unset;
            a, button {
                padding: 6px 18px;
                font-size: 16px;
            }
        }
    }
    &-BackButton {
        background-color: var(--color-white);
        color: var(--color-blue);
        text-transform: uppercase;
        border-radius: 5px;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 1px;
        &:hover {
            cursor: pointer;
        }
        @include mobile {
            font-size: 15px;
        }
    }
    &-Input {
        border: 1px solid var(--color-blue);
        width: 100%;
    }
    &-RadioButtons {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        counter-reset: a;
        row-gap: 10px;
        column-gap: 25px;
    }
    &-RadioButton {        
        list-style-type: upper-alpha;
        border: 1px solid var(--color-blue);
        border-radius: 50px;
        padding: 0;
        min-width: 150px;
        min-height: 35px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        padding-inline-start: 35px;
        overflow: hidden;
        text-align: center;
        margin-bottom: 0;
        @include mobile {
            min-width: 250px;
        } 
        &:last-child{
            margin-bottom: 0 !important;
        }
        &::before {
            counter-increment: a;
            content: counter(a, upper-alpha);
            position: absolute;
            top: 0;
            left: 0 !important;
            height: 35px;
            width: 35px;
            background: var(--color-blue);
            border-radius: 50%;
            padding-right: 0 !important;
            color: var(--color-white);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            font-weight: 500;
            font-size: 16px;
            @include mobile {
                font-size: 14px;
                height: 33px;
                width: 33px;
            }
        }
        &:hover, &_isSelected {
            color: var(--color-white);
            background-color: var(--color-blue);
            cursor: pointer;
            &::before {
                background: var(--color-white);
                color: var(--color-blue);
                font-weight: 600;
                font-size: 18px;
                @include mobile {
                    font-size: 16px;
                }
            }
        }
    }
    &-RangeOptions {
        background: linear-gradient(to right, #E9232C, #EC9521, #3E9D47);
        margin-block: 20px;
        display: flex;
        &Button {
            flex: 1 1;
            height: 40px;
            color: #ffffff;
            font-size: 17px;
            box-shadow: 0px 0px 3px -2px #000000 inset;
            opacity: 0.8;
            border: 2px solid transparent;
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
            &_isSelected {
                border: 2px solid #572020;
                opacity: 1;
            }
        }
    }
    &-Actions {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    &-Radio {
        margin-block: 20px;
    }
    &-Text {
        margin-block: 20px;
    }
    .Disabled {
        filter: brightness(0.8);
    }
}
