/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* stylelint-disable */ 

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-placeholder-color: var(--secondary-dark-color);
    --newsletter-subscription-button-background: var(--link-color);
}

.NewsletterSubscription {

    .FieldForm {
        &-Body {
            display: flex;
            @include mobile {
                margin-bottom: 40px;
            }
        }
        &-Fieldset {
            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-block-start: 0;

                &-Message {
                    position: absolute;
                }

                input {
                    width: 302px;
                    height: 30px;
                    background: #575757;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: white;
                    border: 1px solid #575757;
                    text-align: left;
                    @include mobile {
                        width: 220px;
                    }

                    &::placeholder {
                        color: var(--newsletter-subscription-placeholder-color);
                    }
                }
            }
        }

        &-Fields {
            margin-block-end: 0;
        }
    }

    .Form {
        &_isInvalid {
            margin-block-end: 18px;
        }
    }

    .Button {
        width: 77px;
        height: 32px;
        border: 1px solid #FFFFFF;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin-left: 20px;
        letter-spacing: 1px;
        text-transform: capitalize;
        @include mobile {
            width: 58px;
        }
        &:hover {
            width: 77px;
            height: 32px;
            border: 1px solid #bebcbc;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #bebcbc;
            margin-left: 20px;
            letter-spacing: 1px;
            text-transform: capitalize;
            @include mobile {
                width: 58px;
            }
        }
        &_isNewsletter {
            text-indent: -99999px;
            overflow: hidden;
            width: 50px;
            &:hover {
                width: 50px;
            }
            &::after, &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                box-sizing: border-box;
                border: 1px solid #fff;
            }
            &::after {
                width: 12px;
                height: 12px;
                -webkit-transform: rotate(45deg) translate(calc(-50% - 4px),calc(-50% - 4px));
                transform: rotate(45deg) translate(calc(-50% - 4px),calc(-50% - 4px));
                -webkit-transform-origin: top left;
                transform-origin: top left;
                border-top-color: transparent;
                border-left-color: transparent;
            }
            &::before {
                width: 20px;
                height: 14px;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
        }
    }
}
